export default {
	"app.login.description": "Sécurisez votre accès ! Connectez-vous pour confirmer les informations de votre compte et soyez prêt pour la toute nouvelle application pour les membres du Midtown qui sera bientôt disponible.",
	"app.login.username": "Nom d'utilisateur",
	"app.login.password": "Mot de passe",
	"app.login.forgot": "Nom d'utilisateur/mot de passe oublié",
	"app.login.login": "Se connecter",
	"app.login.error.invalid": "Le nom d'utilisateur ou le mot de passe que vous avez entré est incorrect. Veuillez réessayer ou contacter le service de support aux membres {supportLink}.",
	"app.login.error.attempts": "Vous avez dépassé le nombre de tentatives de connexion incorrectes. Veuillez réessayer dans 10 minutes ou contacter le service de support aux membres {supportLink}.",
	"app.login.error.notmain": "Les données d'identification ne correspondent pas à celles du titulaire du compte principal. Veuillez entrer les informations d'identification du titulaire du compte principal pour continuer.",

	"app.error.generic": "L'opération demandée a échoué. Veuillez réessayer ou contacter le service de support aux membres {supportLink}.",

	"app.error.support.link.text": "ici",

	"app.passwordreset.description": "Veuillez entrer l'adresse courriel associée à votre compte. Nous vous enverrons un courriel avec vos nouvelles informations d'identification.",
	"app.passwordreset.email": "Adresse courriel",
	"app.passwordreset.reset": "Réinitialiser les données d'identification",
	"app.passwordreset.instructions": "Vous ne voyez pas de courriel ? Vérifiez dans vos courriels indésirables la présence d'une adresse courriel se terminant par @midtownclubs.com.",
	"app.passwordreset.error.email.invalid": "Veuillez saisir une adresse courriel valide.",
	"app.passwordreset.error.notfound": "Votre compte n'a pas été trouvé. Veuillez essayer une autre adresse courriel ou contacter le service de support aux membres {supportLink}.",
	"app.passwordreset.error.multiple": "Votre adresse courriel est associée à plusieurs comptes. Veuillez contacter le service de support aux membres {supportLink}.",

	"app.home.header": "Mettez à jour les informations de votre compte pour la nouvelle application mobile Midtown",
	"app.home.header.instructions": "Vérifiez ou mettez à jour les informations de votre compte pour garantir un accès fluide à la nouvelle application mobile Midtown.",
	"app.home.member.header": "Détails sur les membres adultes",
	"app.home.loading": "Chargement...",
	"app.home.loading.error": "L'opération demandée a échoué. Veuillez réessayer plus tard.",
	"app.home.requiredfields": "*Champ obligatoire",
	"app.home.member.instructions": "Chaque membre a besoin d'une adresse courriel unique pour accéder à la nouvelle application Midtown.",
	"app.home.member.type.primary": "Détenteur du compte principal",
	"app.home.member.type.secondary": "Adulte",
	"app.home.member.email": "*Courriel",
	"app.home.member.phoneNumber": "*Numéro de téléphone portable",
	"app.home.member.birthdate": "*Date de naissance",
	"app.home.member.pick.birthdate": "Choisir un date",
	"app.home.member.username": "*Nom d'utilisateur",
	"app.home.member.password.header": "Mise à jour du mot de passe (facultatif)",
	"app.home.member.password.current": "Mot de passe actuel",
	"app.home.member.password.new": "Nouveau mot de passe",
	"app.home.member.password.confirmation": "Confirmer le nouveau mot de passe",
	"app.home.member.password.requirements": "Le mot de passe doit comporter entre 6 et 12 caractères",

	"app.home.member.error.email.invalid": "Veuillez saisir une adresse courriel valide.",
	"app.home.member.error.email.taken": "L'adresse e-mail a déjà été prise. Veuillez en entrer une autre.",
	"app.home.member.error.email.long": "Le courriel est trop long. Veuillez entrer une adresse courriel de 50 caractères maximum.",
	"app.home.member.error.birthdate.invalid": "Veuillez entrer une date de naissance valide au format jj/mm/aaaa.",
	"app.home.member.error.birthdate.min": "Veuillez sélectionner une date de naissance plus ancienne.",
	"app.home.member.error.birthdate.max": "Veuillez sélectionner une date de naissance plus récente",
	"app.home.member.error.phoneNumber.invalid": "Numéro de téléphone portable n'est pas valide",
	"app.home.member.error.guardian.required": "Veuillez sélectionner un tuteur",
	"app.home.member.error.password.donotmatch": "Les mots de passe ne correspondent pas",
	"app.home.member.error.currentpassword.missing": "Veuillez entrer votre mot de passe actuel",
	"app.home.member.error.currentpassword.invalid": "Votre mot de passe actuel n'est pas valide. S'il vous plaît vérifier.",
	"app.home.member.error.username.invalid": "Veuillez saisir un nom d'utilisateur valide.",
	"app.home.member.error.username.taken": "Ce nom d'utilisateur a déjà été utilisé. Veuillez en entrer une autre.",
	"app.home.member.error.username.long": "Le nom d'utilisateur est trop long. Veuillez entrer un nom d'utilisateur de 100 caractères maximum.",

	"app.home.children.header": "Détails du membre enfant",
	"app.home.member.type.child": "Enfant",
	"app.home.children.instructions": "Vérifiez les informations de votre/vos enfant(s). Si vous souhaitez qu'ils aient accès à l'application Midtown, vous devez ajouter une adresse courriel unique pour chaque enfant.",
	"app.home.member.child.access.question": "Voulez-vous que cet enfant ait accès à l'application ?",
	"app.home.member.child.yes": "<b>Oui</b>, je veux que mon enfant ait accès à l'application et je fournirai une adresse courriel unique.",
	"app.home.member.child.no": "<b>Non</b>, je ne souhaite pas que mon enfant ait accès à l'application. J'aimerais que les courriels concernant cet enfant (par exemple, les confirmations d'inscription) soient transmis au tuteur sélectionné ci-dessous.",
	"app.home.member.error.appaccesstype.required": "Veuillez sélectionner l'une des options ci-dessus.",

	"app.home.submit.instructions": "Veuillez lire attentivement les informations ci-dessus avant de les soumettre. Ce formulaire ne peut être soumis qu'une seule fois.",
	"app.home.submit": "Soumettre",
	"app.home.submit.error.title": "Erreur",
	"app.home.submit.error.message": "Impossible d'envoyer le formulaire, vérifier s'il y a des erreurs",

	"app.success.header": "Mise à jour du compte réussie",
	"app.success.message": "Les informations de votre compte ont été mises à jour avec succès. Pour toute question ou mise à jour future, contactez le support aux membres en remplissant {supportForm} ou en appelant le numéro de téléphone principal de votre club.",
	"app.success.message.link.text": "ce formulaire",
	"app.success.promo.message": "Vous voulez voir des aperçus hebdomadaires de ce qui est à venir dans votre nouvelle application pour les membres ? {appLink}",
	"app.success.promo.link.url": "https://www.midtown.com/fr/member-app/",
	"app.success.promo.link.text": "cliquez ici",

	"app.submitted.header": "Votre compte a été mis à jour",
	"app.submitted.message": "Il semble que vous ayez déjà mis à jour vos informations et que vous êtes inscrit pour gagner des tirages au sort hebdomadaires jusqu'au lancement de l'application cet automne. Pour toute question ou mise à jour future, veuillez contacter les services aux membres en remplissant {supportForm} ou en appelant le numéro de téléphone principal de votre club.",

	"app.alt.midtown.logo": "Logo Midtown",
	"app.alt.warning.icon": "Icône attention",
	"app.alt.stars.image": "Image étoiles",
}